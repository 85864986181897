import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  connect() {
    if (!this.modalElement) {
      this.createModal();
    }
    this.initialContentHeight = 0;
  }


  disconnect() {
  }



  createModal() {
    const modal = document.createElement("div");
    modal.id = "global-modal";
    modal.classList.add("forecast-modal");
    modal.innerHTML = '<div class="forecast-modal-content"></div>';
    document.body.appendChild(modal);
  }

  open(event) {
    event.preventDefault();
    this.fetchAndDisplayModal(this.urlValue || event.currentTarget.href);
  }

  quick_update(event) {
    event.preventDefault();
    this.fetchAndDisplayModal(event.currentTarget.href);
  }

  fetchAndDisplayModal(url) {
    if (!this.modalElement) {
      console.error("Modal not found");
      return;
    }

    this.fetchModalContent(url)
      .then((html) => {
        this.modalContentElement.innerHTML = html;
        this.modalElement.classList.add("open");
        // Calculez la hauteur initiale après le rendu du contenu
        // R��cupérer les coordonnées depuis les data attributes
        const spotCoordinates = this.modalContentElement.querySelector(
          "[data-spot-coordinates]"
        );
        if (spotCoordinates && window.map) {
          const lat = parseFloat(spotCoordinates.dataset.latitude);
          const lng = parseFloat(spotCoordinates.dataset.longitude);

          // Récupérer le zoom actuel
          const currentZoom = window.map.getZoom();

          // Utiliser ce zoom pour le projet et unproject
          const pixelPoint = window.map.project([lat, lng], currentZoom);
          // window.innerHeight représente la hauteur totale de la fenêtre du navigateur en pixels
          // On ajoute 1/4 de cette hauteur au point y pour décaler la carte vers le bas
          // Cela permet d'avoir le marker visible au dessus de la modale qui s'affiche en bas
          pixelPoint.y += window.innerHeight / 10;
          const offsetPoint = window.map.unproject(pixelPoint, currentZoom);

          // Animer la carte vers le nouveau point
          window.map.flyTo(offsetPoint, currentZoom, {
            animate: true,
            duration: 1
          });
        }

        // Attendez que le contenu soit rendu
        setTimeout(() => {
          this.setInitialContentHeight();
          this.addTabListeners();
        }, 0);
      })
      .catch((error) => console.error("Error fetching modal content:", error));
  }

  fetchModalContent(url) {
    return fetch(url, {
      method: "GET",
      headers: {
        "X-CSRF-Token": this.csrfToken,
        Accept: "text/html",
      },
      credentials: "same-origin",
    }).then((response) => response.text());
  }


  get modalElement() {
    return document.getElementById("global-modal");
  }

  get modalContentElement() {
    return this.modalElement?.querySelector(".forecast-modal-content");
  }

  get csrfToken() {
    return document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content");
  }

  setInitialContentHeight() {
    const previsionsTab = this.modalElement.querySelector('#nav-home');
    if (previsionsTab) {
      this.initialContentHeight = previsionsTab.offsetHeight;
      this.modalContentElement.style.minHeight = `${this.initialContentHeight +20 }px`;
    }
  }

  addTabListeners() {
    const tabs = this.modalElement.querySelectorAll('[data-toggle="tab"]');
    tabs.forEach(tab => {
      tab.addEventListener('shown.bs.tab', () => {
        this.adjustModalHeight();
      });
    });
  }

  adjustModalHeight() {
    const activeTab = this.modalElement.querySelector('.tab-pane.active');
    if (activeTab) {
      const contentHeight = Math.max(activeTab.offsetHeight, this.initialContentHeight);
      this.modalContentElement.style.minHeight = `${contentHeight}px`;
    }
  }
}
