// gère la fermeture de la modal d'affichage des prévisions

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("keydown", this.handleEscape);
    this.modalElement = document.getElementById("global-modal");

    // Ajouter l'écouteur d'événement pour le déplacement de la carte
    if (window.map) {
      window.map.on("dragstart", this.handleMapDrag.bind(this));
    }
  }

  disconnect() {
    document.removeEventListener("keydown", this.handleEscape);

    // Retirer l'écouteur d'événement lors de la déconnexion
    if (window.map) {
      window.map.off("dragstart", this.handleMapDrag.bind(this));
    }
  }

  close() {
    this.modalElement?.classList.remove("open");
  }

  handleEscape = (event) => {
    if (event.key === "Escape") {
      this.close();
    }
  };

  handleMapDrag = () => {
    this.close();
  };
}
