// app/javascript/controllers/display_pay_day_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["table"];

  connect() {
    this.setupOverlays();
  }

  setupOverlays() {
    const table = this.tableTarget.querySelector("table");
    if (!table) return;

    // Créer un conteneur positionné pour les overlays
    const overlayContainer = document.createElement("div");
    overlayContainer.classList.add("overlay-container");
    table.parentNode.insertBefore(overlayContainer, table);
    overlayContainer.appendChild(table);

    // Identifier et créer les overlays
    this.createOverlayButtons(table, overlayContainer);

    // Ajouter les styles
    this.addOverlayStyles();
  }

  createOverlayButtons(table, container) {
    const rows = [2, 3, 4, 5, 6];
    let premiumSections = this.findPremiumSections(table, rows[0]);
    const isSignedIn = this.element.dataset.userSignedIn === "true";

    premiumSections.forEach((section) => {
      const dimensions = this.calculateBlockDimensions(table, rows, section);
      if (isSignedIn) {
        this.createPremiumUserButton(container, dimensions);
      } else {
        this.createVisitorButton(container, dimensions);
      }
    });
  }

  findPremiumSections(table, rowIndex) {
    const sections = [];
    const row = table.rows[rowIndex];
    let currentSection = null;

    for (let i = 0; i < row.cells.length; i++) {
      const cell = row.cells[i];
      if (cell && cell.classList.contains("payday-day")) {
        if (!currentSection) {
          currentSection = { start: i, end: i };
        } else {
          currentSection.end = i;
        }
      } else if (currentSection) {
        sections.push({ ...currentSection });
        currentSection = null;
      }
    }

    if (currentSection) {
      sections.push(currentSection);
    }

    return sections;
  }

  calculateBlockDimensions(table, rows, section) {
    const firstCell = table.rows[rows[0]].cells[section.start];
    const lastCell = table.rows[rows[rows.length - 1]].cells[section.start];
    const rect = firstCell.getBoundingClientRect();
    const lastRect = lastCell.getBoundingClientRect();
    const tableRect = table.getBoundingClientRect();

    // Calculer la largeur totale du bloc
    let width = 0;
    for (let i = section.start; i <= section.end; i++) {
      const cell = table.rows[rows[0]].cells[i];
      if (cell) {
        width += cell.offsetWidth;
      }
    }

    return {
      left: rect.left - tableRect.left,
      top: rect.top - tableRect.top,
      width: width,
      height: lastRect.bottom - rect.top,
    };
  }

  createPremiumUserButton(container, dimensions) {
    const button = document.createElement("div");
    button.classList.add("premium-overlay-button");

    button.innerHTML = `
     <div class="premium-button-container">
       <div class="premium-button"
            data-controller="modal"
            data-action="click->modal#loadContent"
            data-url="/information/become_member">
         <div class="premium-content">
           <div class="premium-icon">🔓</div>
           <div class="premium-text">${this.getPremiumButtonText()}</div>
         </div>
         <div class="premium-text">9.99€/year</div>
         <dialog data-modal-target="dialog" class="dialog"></dialog>
       </div>
     </div>
   `;

    this.applyButtonStyles(button, dimensions);
    container.appendChild(button);
  }

  createVisitorButton(container, dimensions) {
    const button = document.createElement("div");
    button.classList.add("premium-overlay-button");

    button.innerHTML = `
     <div class="premium-button-container">
       <a href="/users/sign_in" class="premium-button">
         <div class="premium-content">
           <div class="premium-icon">🔓</div>
           <div class="premium-text">${this.getVisitorButtonText()}</div>
         </div>
       </a>
     </div>
   `;

    this.applyButtonStyles(button, dimensions);
    container.appendChild(button);
  }

  applyButtonStyles(button, dimensions) {
    Object.assign(button.style, {
      position: "absolute",
      left: `${dimensions.left}px`,
      top: `${dimensions.top}px`,
      width: `${dimensions.width}px`,
      height: `${dimensions.height}px`,
    });
  }

  getPremiumButtonText() {
    return document.documentElement.lang === "fr"
      ? "Débloquer toutes les fonctionnalités"
      : "Unlock all Features";
  }

  getVisitorButtonText() {
    return document.documentElement.lang === "fr"
      ? "Se connecter pour voir plus"
      : "Sign in to Unlock Features";
  }

  addOverlayStyles() {
    const styleSheet = document.createElement("style");
    styleSheet.textContent = `
     .overlay-container {
       position: relative;
       display: inline-block;
     }

     .premium-overlay-button {
       position: absolute;
       display: flex;
       align-items: center;
       justify-content: center;
       background: linear-gradient(45deg, #FFE6E7, #FFE6E7);
       border-radius: 8px;
       z-index: 10;
       padding: 4px;
       box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
     }

     .premium-button-container {
       width: 75%;
       height: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
     }

     .premium-content {
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       gap: 4px;
       width: 100%;
     }

     .premium-icon {
       font-size: 1.5em;
       line-height: 1;
     }

     .premium-text {
       text-align: center;
       font-size: 0.9em;
       line-height: 1.2;
       max-width: 100%;
       word-wrap: break-word;
     }

     .premium-button {
       width: 100%;
       min-height: 80px;
       padding: 8px;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       background: #FF4742;
       border-radius: 8px;
       color: white;
       cursor: pointer;
       transition: all 0.3s ease;
       text-decoration: none;
       box-shadow: 0 2px 4px rgba(255, 71, 66, 0.2);
     }

     .premium-button:hover {
       transform: scale(1.02);
       background: #FF5D58;
       text-decoration: none;
       color: white;
     }

     .premium-price {
       font-size: 0.8em;
       opacity: 0.9;
       margin-top: 4px;
       color: white;
     }

     @media (max-width: 768px) {
       .premium-button {
         min-height: 60px;
         padding: 4px;
       }

       .premium-icon {
         font-size: 1.2em;
       }

       .premium-text {
         font-size: 0.75em;
       }

       .premium-price {
         font-size: 0.7em;
       }
     }
   `;

    document.head.appendChild(styleSheet);
  }
}
