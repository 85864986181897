import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "portal"];

  connect() {
    setTimeout(() => {
      this.createPortal();
      this.spinner = document.getElementById("spinner");
    }, 0);
  }

  createPortal() {
    this.portalContainer = document.getElementById("update-button-portal");

    if (!this.portalContainer || !this.buttonTarget) return;

    // Sauvegarder une référence à l'élément bouton
    const buttonElement = this.buttonTarget;

    this.portalContainer.innerHTML = "";
    this.placeholder = document.createElement("div");
    this.placeholder.style.display = "none";

    buttonElement.parentNode.insertBefore(this.placeholder, buttonElement);

    // Sauvegarder une référence au clone
    this.clonedButton = buttonElement.cloneNode(true);

    // Ajouter l'écouteur d'événement sur le bouton à l'intérieur du formulaire cloné
    const button = this.clonedButton.querySelector("button");
    if (button) {
      button.addEventListener("click", () => {
        if (this.spinner) {
          // this.spinner.style.display = "block";

          // Récupérer le nombre total de spots pour le compteur
          const totalSpots = parseInt(button.dataset.totalSpots) || 0;

          // Afficher et mettre à jour le compteur
          const updateCounter = document.getElementById("updateCounter");
         if (updateCounter) {
           updateCounter.style.display = "block";
           let currentSpot = 1; // Commencer à 1

           const countupInterval = setInterval(() => {
             updateCounter.textContent = `${currentSpot}/${totalSpots}`;
             currentSpot++;

             if (currentSpot > totalSpots) {
               clearInterval(countupInterval);
               updateCounter.style.display = "none";
             }
           }, 1000);
         }
        }
      });
    }

    this.portalContainer.appendChild(this.clonedButton);
    buttonElement.remove();
  }

  disconnect() {
    // Vérifier si on a un placeholder et un bouton cloné
    if (this.placeholder && this.clonedButton) {
      // Retirer le bouton cloné du portail
      this.clonedButton.remove();
      // Supprimer le placeholder
      this.placeholder.remove();
      // Masquer le spinner si visible
      if (this.spinner) {
        this.spinner.style.display = "none";
      }
    }
  }
}
